<template>

  <div class="home">
    
      <div class="hero-slider">
        
        <slider ref="slider" :options="options">

            <!-- slideritem wrapped package with the components you need -->
            <!-- <slideritem v-for="(item,index) in someList" :key="index">{{item.html}}</slideritem> -->

            <slideritem>
              <div class="slider-content-wrapper one">
                <div class="slider-content">
                  <div class="slider-text heading-image">
                    <span class="cursive">Welcome to</span><img src="@/assets/img/logo-300-light.png" alt="xmarine">
                  </div>
                  <div class="slider-text">
                    <div class="hero-text">Australia's newest marketplace for commercial mariners</div>
                  </div>
                  <div class="slider-text">
                    <span class="cursive">Launching in 2021</span>
                  </div>
                  <div class="slider-text">
                    <span @click="showNotifyModal = true" class="button">Get notified when we launch</span>
                  </div>
                </div>
              </div>
            </slideritem>

            <slideritem>
              <div class="slider-content-wrapper two">
                <div class="slider-content">
                  <div class="slider-text heading-image">
                    <img src="@/assets/img/logo-300-light.png" alt="xmarine">
                  </div>
                  <div class="slider-text">
                    <div class="hero-text">We're building a better way to buy and sell commercial marine gear!</div>
                  </div>
                  <div class="slider-text">
                    <span class="cursive">Fast and simple is the key!</span>
                  </div>
                  <div class="slider-text">
                    <span @click="showNotifyModal = true" class="button">Get notified when we launch</span>
                  </div>
                </div>
              </div>
            </slideritem>

            <slideritem>
              <div class="slider-content-wrapper three">
                <div class="slider-content">
                  <div class="slider-text heading-image">
                    <img src="@/assets/img/logo-300-light.png" alt="xmarine">
                  </div>
                  <div class="slider-text">
                    <div class="hero-text">Buy and sell anything to do with commercial vessels in Australia</div>
                  </div>
                  <div class="slider-text">
                    <span class="cursive">Boats, gear, services, jobs + more!</span>
                  </div>
                  <div class="slider-text">
                    <span @click="showNotifyModal = true" class="button">Get notified when we launch</span>
                  </div>
                </div>
              </div>
            </slideritem>


            <!-- Customizable loading -->
            <div slot="loading">loading...</div>
        </slider>

      </div>

      <div class="container">

          <a target="_blank" href="https://www.facebook.com/xmarine.australia" class="social">
            <font-awesome-icon :icon="['fab', 'facebook-square']"></font-awesome-icon>
            <span class="text">Share Xmarine with your mates</span>
          </a>

          <div class="intro-text">
            <h3>We're building Xmarine for people like you!</h3>
            <p>And we really need your help! Please choose a few items below to tell us what you'd like to see in a commercial marine marketplace.</p>
          </div>

          <div class="clear">
            
          </div>
          
          <!-- <div class="fb-like" data-href="https://www.facebook.com/xmarine.australia" data-width="300" data-layout="standard" data-action="like" data-size="large" data-share="false"></div> -->

          <div class="questionnaire-wrapper">

              <div class="spacer s50"></div>
              
              <h3>Is there anything in the marine world that you're looking to buy?</h3>
              <div class="search-wrapper">
                <span class="search-pretext">I'm looking for</span>
                <span class="input-wrapper"><input type="text" maxlength="120" class="input" placeholder="Type something here..." v-model="searchTerms">
                  <!-- <font-awesome-icon icon="search" /> -->
                </span>
                <div class="clear"></div>
              </div>

              <div class="spacer s30"></div>

              <h3>Have you got anything commercial you would list for FREE?</h3>
              <div class="choice-buttons">
                <span @click="numItems = 'few'" class="choice-button few" :class="{chosen: numItems == 'few'}">Yes, 1 or 2 things</span>
                <span @click="numItems = 'many'" class="choice-button many" :class="{chosen: numItems == 'many'}">Heaps of things</span>
                <span @click="numItems = 'none'" class="choice-button none" :class="{chosen: numItems == 'none'}">Nothing yet</span>
              </div>

              <div class="spacer s30"></div>

              <h3>Which of these marine categories interest you the most?</h3>
              <div class="category-cloud-wrapper">
                <ul class="cat-cloud">
                  <li v-for="(cat, index) in catCloud" :key="cat.title"><span :class="{selected: cat.selected}" @click="updatedCat(cat, index)">{{cat.title}}</span></li>
                </ul>
              </div>

              <div class="spacer s30"></div>

              <h3>Which of these best describes you?</h3>
              <div class="user-type-wrapper">
                <ul class="type-cloud">
                  <li v-for="(type, index) in userTypes" :key="type.title"><span :class="{selected: type.selected}" @click="updatedType(type, index)">{{type.title}}</span></li>
                </ul>
              </div>

              <div class="spacer s30"></div>

              <h3>Which of these are you?</h3>
              <div class="choice-buttons">
                <span @click="chosen = 'buyer'" class="choice-button buyer" :class="{chosen: chosen == 'buyer'}">More of a Buyer</span>
                <span @click="chosen = 'seller'" class="choice-button seller" :class="{chosen: chosen == 'seller'}">More of a Seller</span>
                <span @click="chosen = 'both'" class="choice-button both" :class="{chosen: chosen == 'both'}">A bit of both</span>
                <span @click="chosen = 'neither'" class="choice-button neither" :class="{chosen: chosen == 'neither'}">Neither</span>
              </div>

              <div class="spacer s80"></div>

          </div>



          <div class="clear"></div>

      </div>




      <transition name="fade">
        <div v-if="showSaveButton" class="save-button-wrapper" @click="expandSave = true" :class="{open: expandSave}" v-closable="{exclude: [],handler: 'closeSaveButton'}">
          <div class="save-button parent-element">
            <span class="child-element"><font-awesome-icon icon="thumbs-up" /><br>Save my<br>answers</span>
          </div>
          <div class="save-email parent-element">
            <div class="email-intro"><div class="hide-479">Let me know when Xmarine launches!</div><div class="show-479">Keep me posted!</div></div>
            <span class="email-input"><input class="input" type="email" v-model="bottomNotifyEmail" placeholder="Your email address..."></span>
            <div class="email-buttons">
              <button @click="saveAnswers()" :disabled="!bottomEmailIsValid" class="email-button">Save and Done!</button>
            </div>
          </div>
        </div>
      </transition>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showNotifyModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card is-primary">
          
            <header class="modal-card-head is-primary">
              <p class="modal-card-title">Let me know when Xmarine launches</p>
            </header>
            <section class="modal-card-body">       

              <h3>We can let you know when the newest (and soon to be greatest) commercial marine marketplace in Australia launches!</h3>
              
              <div class="centered">
                <div class="spacer s10"></div>
                <input class="input" type="email" v-model="topNotifyEmail" placeholder="Enter your email address..">
                <div class="buttons">
                  <button class="button is-primary" :disabled="!emailIsValid" @click="saveEmailAddress()">Yes, keep me posted</button>
                </div>
              </div>


            </section>
            <footer class="modal-card-foot">
              <button class="button is-cancel" @click="close">Close</button>
            </footer>        

        </div>
      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showThankYou}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card is-primary">
          
            <header class="modal-card-head is-primary">
              <p class="modal-card-title">Thanks for your help!</p>
            </header>
            <section class="modal-card-body">       

              <h2>Your input is much appreciated!</h2>
              <h3>We promise not to share your email address wth anyone, and we won't hassle you until we launch.</h3>


            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="close">Done</button>
              <div class="clear">
                
              </div>
            </footer>        

            <div class="clear">
              
            </div>

        </div>
      </div>

      <!-- <new-post-button v-if="showNewPostButton"></new-post-button> -->

      
      <page-footer></page-footer>



  </div>

</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { slider, slideritem } from 'vue-concise-slider';
import { db } from '../../main.js';
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';
import NProgress from 'nprogress';
const NewPostButton = () => import('../reusables/NewPostButton.vue');
const PageFooter = () => import('../reusables/PageFooter.vue');

export default {

  name: 'HomePage',
  components: {
    slider,
    slideritem,
    NProgress,
    NewPostButton,
    PageFooter
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.loadUserTypes();
    this.loadCatCloud();
  },
  computed: {
    selectedCats() {
      return this.catCloud.filter((cat) => cat.selected).map((cat) => cat.title).join(', ');
    },
    selectedTypes() {
      return this.userTypes.filter((type) => type.selected).map((type) => type.title).join(', ');
    },
    showSaveButton() {
      return this.selectedCats.length > 0 || this.selectedTypes.length > 0 || this.chosen != '' || this.searchTerms != '' || this.numItems != '';
    },
    emailIsValid() {
      // return this.topNotifyEmail == '';
      return this.reg.test(this.topNotifyEmail);
    },
    bottomEmailIsValid() {
      return this.reg.test(this.bottomNotifyEmail);
    }
  },
  data() {
    return {
      catCloud: [],
      userTypes: [],
      chosen: '',
      numItems: '',
      searchTerms: '',
      options: {
        currentPage: 0,
        autoplay: '5000',
        loop: true,

      },
      topNotifyEmail: '',
      bottomNotifyEmail: '',
      showNotifyModal: false,
      showThankYou: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      expandSave: false,
      showNewPostButton: true,
      scrollPosition: 0,
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0

    }
  },

  methods: {

    handleScroll(event) {
      // console.log('1234');
      var currentScrollPosition = event.srcElement.scrollTop;
      if (currentScrollPosition > this.scrollPosition) {
          console.log("Scrolling down");
      }
      this.scrollPosition = currentScrollPosition;
    },
    // handleScroll() {
    //   if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
    //     this.scrolled = true;
    //     // move up!
    //   } 
      
    //   if (this.lastPosition > window.scrollY) {
    //     this.scrolled = false;
    //     // move down
    //   }
      
    //   this.lastPosition = window.scrollY;
    //   // this.scrolled = window.scrollY > 250;
    // },

    loadUserTypes() {
      let self = this;
      db.collection('helpers').doc('helperUserTypes').collection('userTypes').orderBy('index','asc').onSnapshot((querySnapshot) => {
        self.userTypes = [];
        querySnapshot.forEach((doc) => {
          self.userTypes.push({
            selected: false,
            title: doc.data().title,
          });
        });
      });
    },

    loadCatCloud() {
      let self = this;
      db.collection('helpers').doc('helperUserCats').collection('userCats').orderBy('index','asc').onSnapshot((querySnapshot) => {
        self.catCloud = [];
        querySnapshot.forEach((doc) => {
          self.catCloud.push({
            selected: false,
            title: doc.data().title,
          });
        });
      });
    },

    updatedCat(cat, index) {
      this.catCloud[index].selected = !this.catCloud[index].selected;
    },

    updatedType(type, index) {
      this.userTypes[index].selected = !this.userTypes[index].selected;
    },

    saveAnswers() {
      NProgress.start();
      let self = this;
      var saveInterestedData = firebase.functions().httpsCallable('saveInterestedData');
      saveInterestedData({
        email: self.bottomNotifyEmail,
        searchTerms: self.searchTerms,
        numItemsToSell: self.numItems,
        buyerOrSeller: self.chosen,
        selectedTypes: self.selectedTypes,
        selectedCats: self.selectedCats,
      }); 
      this.close();
      NProgress.done();
      this.showThankYou = true;
    },

    close() {
      this.showNotifyModal = false;
      this.topNotifyEmail = '';
      this.searchTerms = '';
      this.chosen = '';
      this.bottomNotifyEmail = '';
      this.numItems = '';
      this.catCloud.forEach((cat) => {
        cat.selected = false;
      });
      this.userTypes.forEach((type) => {
        type.selected = false;
      });
      this.showThankYou = false;
    },

    isEmailValid(email) {
      return (email == "") ? false : (this.reg.test(email)) ? true : false;
    },

    saveEmailAddress() {
      let self = this;
      NProgress.start();
      var saveInterestedData = firebase.functions().httpsCallable('saveInterestedData');
      saveInterestedData({
        email: self.topNotifyEmail,
        searchTerms: self.searchTerms,
        numItemsToSell: self.numItems,
        buyerOrSeller: self.chosen,
        selectedTypes: self.selectedTypes,
        selectedCats: self.selectedCats,
      }); 
      this.close();
      NProgress.done();
      this.showThankYou = true;
    },

    closeSaveButton() {
      this.expandSave = false;
    }


  }
}

</script>


<style lang="scss" scoped>
  

  .home {
    position: relative;
  }
  .social {
    margin-top: 2rem;
    display: block;
    svg {
      font-size: 3rem;
      vertical-align: middle;
      color: dodgerblue;
      margin-right: 10px;
    }
    .text {
      color: dodgerblue;
      font-size: 1.3rem;
      line-height: 1.4rem;
      display: inline-block;
    }
  }
  .hero-slider {
    height: 600px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
    .slider-content-wrapper {
      height: 100%;
      position: relative;
      &.one {
        background: url('../../assets/img/boats-wharf-dark.jpg') no-repeat center center;
        background-size: cover;
        width: 100%;
      }
      &.two {
        background: url('../../assets/img/old-man-nets-dark.jpg') no-repeat center center;
        background-size: cover;
        width: 100%;
      }
      &.three {
        background: url('../../assets/img/three-boats-dark.jpg') no-repeat center center;
        background-size: cover;
        width: 100%;
      }
      .slider-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 95%;
        max-width: 1140px;
        text-align: left;
        .slider-text {
          white-space: normal;
          line-height: 2.8rem;
          .cursive {
            font-family: 'Shadows Into Light Two', cursive;
            letter-spacing: 1px;
          }
          .hero-text {
            font-size: 2rem;
            color: #f1ff2f;
            font-weight: bold;
            line-height: 2.2rem;
            margin-bottom: 1rem;
          }
          &.heading-image {
            span {
              display: inline-block;
              vertical-align: super;
              height: 70px;
              font-family: 'Shadows Into Light Two', cursive;
            }
            img {
              max-width: 220px;
              vertical-align: bottom;
            }
          }
          .button {
            border: none;
            color: #fff;
            background: deepskyblue;
            padding: 13px 22px;
            height: auto;
            font-size: 1.2rem;
            margin-top: 1rem;
            transition: 0.3s;
            &:hover {
              background: mediumseagreen;
            }
          }
        }
      }
    }
  }

  .intro-text {
    text-align: center;
    margin-top: 4rem;
    position: relative;
    h3 {
      font-family: 'Shadows Into Light Two', cursive;
      font-size: 2.2rem;
      color: deepskyblue;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.3rem;
      max-width: 600px;
      margin: 0 auto;
    }
    &:before {
      content: "";
      width: 200px;
      height: 2px;
      background: #ddd;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      content: "";
      width: 200px;
      height: 2px;
      background: #ddd;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .questionnaire-wrapper {
    
    h3 {
      text-align: center;
      margin-top: 2rem;
      font-size: 1.3rem;
      color: mediumseagreen;
      font-weight: bold;
    }
    .search-wrapper {
      border: 1px solid #eee;
      padding: 20px;
      margin-top: 10px;
      height: 110px;
      .search-pretext {
        width: 160px;
        float: left;
        display: inline-block;
        text-align: right;
        padding-right: 1rem;
        height: 60px;
        line-height: 55px;
        font-size: 1.2rem;
      }
      .input-wrapper {
        float: left;
        display: inline-block;
        width: calc(100% - 200px);
        position: relative;
        height: 60px;
        line-height: 50px;
        transition: 0.3s;
        input[type="text"] {
          border: none;
          box-shadow: none;
          border-bottom: 1px solid #ddd;
          height: 52px;
          font-size: 1.2rem;
          color: dodgerblue;
        }
        svg {
          position: absolute;
          right: 20px;
          top: 16px;
          font-size: 1.5rem;
          color: #999;
          cursor: pointer;
          transition: 0.3s;
        }
        &:focus-within svg {
          font-size: 1.7rem;
          top: 14px;
          color: orange;
        }
      }
    }
    .category-cloud-wrapper {
      .cat-cloud {
        margin-top: 2em;
        text-align: center;
        li {
          display: inline-block;
          cursor: pointer;
          margin: 0 12px 1.3rem 0;
          span {
            background: #f9f9f9;
            transition: 0.3s;
            display: block;
            padding: 16px 25px;
            font-size: 1.1rem;
            border-radius: 30px;
            &:hover {
              // background: aliceblue;
              color: #444;
              background: #d3eefd;
              // color: #fff;
            }
            &.selected {
              background: #8AD1F6;
              color: #fff;
            }
          }
        }
      }
    }
    .user-type-wrapper {
      .type-cloud {
        margin-top: 2em;
        text-align: center;
        li {
          display: inline-block;
          cursor: pointer;
          margin: 0 12px 1.3rem 0;
          span {
            background: #f9f9f9;
            transition: 0.3s;
            display: block;
            padding: 16px 25px;
            font-size: 1.1rem;
            border-radius: 30px;
            &:hover {
              // background: aliceblue;
              color: #444;
              background: #ffe0a6;
              // color: #fff;
            }
            &.selected {
              background: #fdca6c;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .choice-buttons {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    .choice-button {
      display: inline-block;
      margin: 0 12px 15px 0;
      background: #f9f9f9;
      padding: 20px 25px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 10px;      
      background: #eee;
      color: #079df7;
      font-weight: bold;
      &.buyer {
        &:hover, &.chosen {
          background: #0bd40b;
          color: #fff;
        }
      }
      &.seller {
        &:hover, &.chosen {
          background: #fd4f4f;
          color: #fff;
        }
      }
      &.both {
        &:hover, &.chosen {
          background: #59c2c5;
          color: #fff;
        }
      }
      &.neither {
        &:hover, &.chosen {
          background: #b9afaf;
          color: #fff;
        }
      }
      &.few {
        &:hover, &.chosen {
          background: #5bc55b;
          color: #fff;
        }
      }
      &.many {
        &:hover, &.chosen {
          background: #ec8181;
          color: #fff;
        }
      }
      &.none {
        &:hover, &.chosen {
          background: #59c2c5;
          color: #fff;
        }
      }
    }
  }

  .save-button-wrapper {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: 50%;
    background: #33d5f2;
    color: #fff;
    width: 180px;
    height: 180px;
    font-size: 1.6rem;
    font-weight: bold;
    transition: 0.4s;
    line-height: 27px;
    overflow: hidden;
    z-index: 99;
    // &:hover {
    //   width: 600px;
    //   border-radius: 200px;
    //   max-width: 95%;
    // }
    &.open {
      width: 600px;
      border-radius: 200px;
      max-width: 95%;
    }
    .save-button {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      // background: #33d5f2;
      background: #20b7d2;
      color: #fff;
      width: 180px;
      height: 180px;
      font-size: 1.6rem;
      font-weight: bold;
      transition: 0.4s;
      line-height: 27px;
      z-index: 99;
      cursor: pointer;
      // &:hover {
      //   width: 400px;
      //   border-radius: 200px;
      // }
      span {
        top: 25%;
      }
    }

    .save-email {
      position: absolute;
      top: 0;
      right: 20px;
      border-radius: 50%;
      background: #33d5f2;
      color: #fff;
      // width: calc(100% - 220px);
      width: 380px;
      height: 180px;
      font-size: 1.6rem;
      font-weight: bold;
      transition: 0.4s;
      line-height: 27px;
      z-index: 98;
      .email-intro {
        font-size: 1.1rem;
        margin-top: 25px;
        display: block;
      }
      .email-input {
        display: block;
        margin-top: 8px;
        input[type="email"] {
          height: auto;
          padding: 12px;
          width: 90%;
        }
      }
      .email-buttons {
        cursor: pointer;
        margin-top: 5px;
        button.email-button {
          background: #9bffcd;
          padding: 12px 35px;
          font-size: 16px;
          line-height: 1px;
          border-radius: 30px;
          color: #0b8da5;
          transition: 0.3s;
          /* height: auto; */
          border: none;
          line-height: 16px;
          cursor: pointer;
          &:hover {
            background: limegreen;
            color: #fff;
          }
        }
      }
    }
  }

    

  .footer {
    text-align: center;
    margin-top: 5rem;
    padding: 5rem 2rem 15rem;
    background: #fff;
    margin-bottom: 6rem;
  }



  @media only screen and (max-width: 600px) {

    .questionnaire-wrapper {
      .search-wrapper {
        height: auto;
        .search-pretext {
          float: none;
          width: 100%;
          text-align: center;
        }
        .input-wrapper {
          float: none;
          width: 100%;
        }
      }
      .category-cloud-wrapper {
        .cat-cloud {
          li {
            span {
              padding: 12px 15px;
              font-size: 1rem;
            }
          }
        }
      }
      .user-type-wrapper {
        .type-cloud {
          li {
            span {
              padding: 12px 15px;
              font-size: 1rem;
            }
          }
        }
      }
    }

    .save-button-wrapper {
      width: 140px;
      height: 140px;
      .save-button {
        width: 140px;
        height: 140px;
        font-size: 1.3rem;
        line-height: 1.5rem;
        display: inline-block;
      }
      &.open {
        width: 95%;
        right: 2.5%;
        height: 140px;
        .save-button {
          display: none;
        }
        .save-email {
          position: relative;
          right: auto;
          width: 95%;
          margin: 0 auto;
          .email-buttons {
            margin-top: 5px;
          }
        }
        .email-intro {
          margin-top: 5px;
        }
        .email-input {
          margin-top: 5px;
          input[type="email"] {
            padding: 9px;
            margin-bottom: 2px;
          }
        }
      }
    }

  }


</style>










